.pakages_main_div > h2 {
  padding: 1rem 7rem;
  margin: 0;
  background-color: white;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.pakages_grid {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem 5rem;
  row-gap: 2.5rem;
  column-gap: 2.5rem;
}

@media screen and (max-width: 550px) {
  .pakages_grid {
    padding: 0;
    padding-bottom: 7rem;
  }
  .pakages_main_div > h2 {
    padding: 1rem 1rem;
  }
  .pakages_main_div {
    margin: 0rem 1rem;
  }
}
