.Summary_main {
  background-color: #f5f5f5;
  padding: 0rem 4rem;
  padding-bottom: 8rem;
  display: flex;
  justify-content: center;
}

.OrderSummary {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.6rem;
}

.billrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.billrow > p {
  font-weight: 500;
  color: grey;
}

.billrow > p,
.billrow > h4 {
  padding-right: 2rem;
  margin: 0;
  margin: 0.4rem 0;
}

.billrow > h5 {
  margin: 0;
}

.billDetails {
  padding: 1rem;
  border-radius: 0.6rem;
  background-color: white;
  padding-bottom: 2rem;
  margin-left: 2rem;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

.billDetails > h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
  /* border-bottom: 1px solid grey; */
}

.hardcopy {
  font-size: 1.3rem;
}

.hardcopy > input {
  border-radius: 50%;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #0eb6ff;
  border-color: #0eb6ff;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.hardcopy {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hardcopy > span {
  margin-left: 1rem;
}

.offers_div_main {
  margin-bottom: 1rem;
  border-radius: 0.4rem;
  background-color: white;
  padding: 0 1rem;
  width: 100%;
}

.offers_div {
  color: rgb(74, 0, 153);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.offers_div > img {
  width: 3.8rem;
  object-fit: contain;
  position: absolute;
  top: -50%;
  left: 0;
}

.offers_div > h2 {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0.5rem;
  margin-left: 4rem;
}

.inputCoupon.desktopElement,
.OrderSummary_main.desktopElement {
  display: none;
}

.orderSummary_box1,
.AvailableCoupon {
  background-color: white;
  padding-bottom: 2rem;
  border-radius: 0.4rem;
  width: 26rem;
}

.AvailableCoupon {
  margin-top: 2rem;
  text-align: center;
  padding: 0.5rem 0;
}

.headingOrder {
  background-color: #0eb6ff;
  font-size: 1rem;
  margin: 0;
  color: white;
  padding: 1rem;
}

.patientName,
.pakageName > h3,
.pakageAvialability > p,
.pakageAvialability > h3,
.Coupons_now > h3 {
  font-size: 1rem;
  margin: 0;
}

.patientName,
.pakageName,
.pakageAvialability {
  padding: 1rem;
  padding-bottom: 0;
}

.AvailableCoupon > h2 {
  font-size: 1.3rem;
  margin: 0;
}

.Coupons_now {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem;
  box-sizing: border-box;
  background-color: #f5f5f5;
  padding: 0.5rem;
}

@media (min-width: 1100px) {
  .hardcopy.mobileElement {
    display: none;
  }

  .mobileElement {
    display: none;
  }

  .inputCoupon.desktopElement {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
  }

  .OrderSummary_main.desktopElement {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .inputCoupon > input {
    font-size: 1.2rem;
    padding: 0.5rem 0.8rem;
    border: none;
    background-color: #ededed;
    border-radius: 0.2rem;
    font: 3;
  }

  .inputCoupon > button,
  .Coupons_now > button {
    font-size: 1rem;
    padding: 0.5rem 0.8rem;
    background-color: #0eb6ff;
    color: white;
    border-radius: 0.2rem;
    font: 1;
    border: none;
  }

  .offers_div_main {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 900px) {
  .OrderSummary {
    width: 80%;
  }

  .offers_div > h2 {
    margin: 0.3rem;
    margin-left: 3rem;
  }

  .offers_div_main {
    padding: 0.5rem;
    margin-top: 3rem;
  }

  .Summary_main {
    padding: 1rem;
    padding-bottom: 8rem;
  }
}

@media (max-width: 600px) {
  .OrderSummary {
    width: 95%;
  }
  .Summary_main {
    flex-direction: column;
  }

  .offers_div > img {
    width: 4rem;
    object-fit: contain;
  }
  .AvailableCoupon {
    width: 95%;
    overflow-y: scroll;
  }
}

@media (max-width: 500px) {
  .Summary_main {
    padding: 0rem;
    padding-bottom: 8rem;
  }
  .OrderSummary_main {
    width: 100%;
  }
  .OrderSummary {
    width: 100%;
    font-size: 80%;
    padding: 0rem;
    margin: 0;
  }

  .orderSummary_box1 {
    width: 95%;
    margin: auto;
  }

  .offers_div {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .billDetails {
    margin: 0;
    width: 93%;
  }

  .OrderSummary > h1 {
    margin-bottom: 3rem;
  }

  .offers_div > img {
    width: 3rem;
    object-fit: contain;
  }

  .offers_div > h2,
  .hardcopy > span {
    font-size: 0.9rem;
  }

  .offers_div_main {
    padding: 0;
  }
  .mobileElement {
    display: block;
  }
}
.close_button {
  font-weight: bolder;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.dialog_body_dis {
  width: 400px;
  height: 500px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10000000;
  position: fixed;
  top: 50%;
  left: 43%;
  right: 50%;
  transform: translate(-50%, -50%);
  overflow: scroll;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
