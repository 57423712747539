.selfCheck_icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem 2rem;
}

.selfCheck_circle > img {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
  background-color: #ffffff;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 0.5rem;
}

.selfCheck_icons > h3 {
  font-weight: bold;
  color: #000c83;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

@media (max-width: 800px) {
  .selfCheck_icons {
    margin: 0.5rem;
  }
}

@media (max-width: 700px) {
  .selfCheck_circle > img {
    height: 5rem;
    width: 5rem;
  }
}

@media (max-width: 500px) {
  .selfCheck_circle > img {
    height: 3.5rem;
    width: 3.5rem;
  }

  .selfCheck_icons > h3 {
    font-weight: bold;
    font-size: 0.8rem;
    margin-top: 0.2rem;
  }
}
