.add_package {
    padding: 10px;
}

.add_package form {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.add_package form input,
textarea {
    border: none;
    border-bottom: 2px solid rgb(41, 39, 39);
    font-size: 25px;
    margin-bottom: 20px;
}

.add_package form input {
    padding-bottom: 10px;
}

.add_package form input,
textarea:focus {
    outline: none;
}

.add_package form .add_package_btn {
    border: none;
    background-color: #000C83;
    color: white;
    font-weight: 600;
    font-size: 20px;
    padding: 10px 0px;
    border-radius: 10px;
    width: 200px;
    cursor: pointer;
    margin-top: 20px;
}