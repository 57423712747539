.address_info {
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.myBookings {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 40px;
  /* background-color: #eeeeee; */
}

.orderSummary_box {
  background-color: #ffffff;
  padding-bottom: 2rem;
  border-radius: 0.4rem;
  width: 26rem;
  margin: 10px 20px;
}

.headingOrder {
  background-color: #0eb6ff;
  font-size: 1rem;
  margin: 0;
  color: white;
  padding: 1rem;
}

.patientName,
.pakageName > h3,
.pakageAvialability > p,
.pakageAvialability > h3 {
  font-size: 1rem;
  margin: 0;
}

.patientName,
.pakageName,
.pakageAvialability {
  padding: 1rem;
  padding-bottom: 0;
}

#progressbar {
  margin-bottom: 3vh;
  overflow: hidden;
  color: rgb(252, 103, 49);
  padding-left: 0px;
  margin-top: 3vh;
}

#progressbar li {
  list-style-type: none;
  font-size: x-small;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
  color: rgb(160, 159, 159);
}

#progressbar #step1:before {
  content: "";
  color: rgb(252, 103, 49);
  width: 5px;
  height: 5px;
  margin-left: 0px !important;
  /* padding-left: 11px !important */
}

#progressbar #step2:before {
  content: "";
  color: #fff;
  width: 5px;
  height: 5px;
  margin-left: 32%;
}

#progressbar #step3:before {
  content: "";
  color: #fff;
  width: 5px;
  height: 5px;
  margin-right: 32%;
  /* padding-right: 11px !important */
}

#progressbar #step4:before {
  content: "";
  color: #fff;
  width: 5px;
  height: 5px;
  margin-right: 0px !important;
  /* padding-right: 11px !important */
}

#progressbar li:before {
  line-height: 29px;
  display: block;
  font-size: 12px;
  background: #ddd;
  border-radius: 50%;
  margin: auto;
  z-index: -1;
  margin-bottom: 1vh;
}

#progressbar li:after {
  content: "";
  height: 2px;
  background: #ddd;
  position: absolute;
  left: 0%;
  right: 0%;
  margin-bottom: 2vh;
  top: 1px;
}
.progress-track {
  padding: 0 8%;
}
#progressbar li:nth-child(2):after {
  margin-right: auto;
}

#progressbar li:nth-child(1):after {
  margin: auto;
}

#progressbar li:nth-child(3):after {
  float: left;
  width: 68%;
}
#progressbar li:nth-child(4):after {
  margin-left: auto;
  width: 132%;
}

#progressbar li.active {
  color: black;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: rgb(252, 103, 49);
}

.sec_text2 {
  margin-left: 14px;
}
.sec_text3 {
  margin-left: 28px;
}
.sec_text4 {
  margin-left: 42px;
}

.myReports {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.reports_info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
}
.report_order {
  background-color: #0eb6ff;
  width: 90%;
  padding: 10px;
}
.reports_info .left {
  flex: 0.3;
}
.reports_info .right {
  flex: 0.7;
}

.report_but {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rep_but {
  background-color: #0eb6ff;
}
.rep_but p {
  margin: 2px 8px;
}

.myOffers {
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
  column-gap: 1rem;
  padding: 30px 40px;
  flex-wrap: wrap;
}
.offerName {
  margin: 0 15px;
  margin-top: 5px;
}
.offerName span {
  font-size: medium;
  font-weight: 500;
}
.offerDetails span {
  font-size: medium;
  font-weight: 500;
}

.offerDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-left: 15px;
}

@media screen and (max-width: 550px) {
  .myBookings {
    padding: 10px 5px;
    justify-content: center;
  }
  .patientName,
  .pakageName > h3,
  .pakageAvialability > p,
  .pakageAvialability > h3 {
    font-size: 0.8rem;
    margin: 0;
  }
}
