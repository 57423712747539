@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&family=Roboto+Mono&family=Roboto+Slab:wght@500&family=VT323&display=swap");
.App {
  width: 100%;
}

body {
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
  background-color: #f5f5f5;
  font-family: "Poppins", sans-serif;
}

/* styles for all tables  */
.status_opt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
}
.table-container {
  overflow-x: auto;
}

.simplebar-placeholder {
  height: 0 !important;
  width: 0 !important;
}

/* .table-container table {
  border-collapse: collapse;
  min-width: 600px;
}

.table-container table th {
  font-size: 20px;
  font-weight: bold;
  background-color: gray;
  color: whitesmoke;
}

.table-container table td,
th {
  border: 1px solid black;
  padding: 10px 5px;
  font-size: 18px;
  text-align: center;
  color: rgb(51, 51, 51);
  font-weight: 600;
}

.table-container table .delete-btn {
  border: none;
  background-color: rgb(253, 39, 39);
  color: white;
  border-radius: 5px;
  padding: 3px;
  font-size: 15px;
  cursor: pointer;
  width: 50px;
} */

.table-container table .edit-btn {
  border: none;
  background-color: rgb(194, 194, 39);
  color: white;
  border-radius: 5px;
  padding: 3px;
  font-size: 15px;
  cursor: pointer;
  width: 50px;
}

/* styles for all add buttons  */
.add-btn {
  border: none;
  background-color: rgb(141, 141, 141);
  color: white;
  padding: 10px 5px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

/* styles for all NavLink  */
.nav_link {
  color: white;
  text-decoration: none;
}

.active {
  color: lightseagreen;
}

.options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
}

.options .add_lab {
  margin-left: 8px;
}

.labs_options {
  display: flex;
  width: 50%;
  justify-content: space-around;
}

.labs_false {
  padding: 3px 20px;
  text-align: center;
  cursor: pointer;
}
.tests_false {
  padding: 3px 20px;
  text-align: center;
  cursor: pointer;
}

.labs_active {
  background-color: gainsboro;
  padding: 3px 20px;
}
.tests_active {
  background-color: gainsboro;
  padding: 3px 20px;
}
