.showLabs_main {
  background-color: #f5f5f5;
}

.showLabs_main_div {
  margin: 0rem 4rem;
  /* padding-top: 1rem; */
}

.showLabs_list {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 1rem 5rem;
  border-top-right-radius: 0.4rem;
  column-gap: 2.5rem;
}

.container1 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.4rem;
  margin-bottom: 1.5rem;
  background-color: white;
  padding: 1rem 2rem;
  width: 30rem;
}

.container1 p {
  font-size: 0.9rem;
  font-weight: 600;
  margin: 3px;
}

.labs_div {
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* width: 100%; */
  /* margin-bottom: 1.5rem; */
}

.labs_price {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectLabRadio {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.labs_name h2 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 0;
}

.labs_name p {
  font-size: 0.9rem;
  font-weight: 600;
}

.mobile-element {
  display: none;
}

.lab_logo_img {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

@media screen and (max-width: 900px) {
  .container1 {
    width: 27rem;
  }
  .showLabs_list {
    padding: 0;
  }
}

@media screen and (max-width: 550px) {
  .desktop-element {
    display: none;
  }
  .showLabs_main_div {
    margin: 0rem 1rem;
    padding: 0;
    padding-bottom: 8rem;
  }
  .container1 {
    width: 100%;
    padding: 1rem 1rem;
  }
  .showLabs_list {
    padding: 0;
  }
  .showLabs_list h2 {
    font-size: 1.1rem;
    font-weight: 700;
    margin-top: 0 1rem;
  }
  .labs_name h2 {
    font-size: 1rem;
    font-weight: 700;
    margin-top: 0;
  }
  .selectLabRadio {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }
  .mobile-element {
    display: block;
  }
}
