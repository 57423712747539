.radiology {
    width: fit-content;
    height: 100%;
    background-color: whitesmoke;
}

.radiology > .heading_radiology {
    padding-left: 15px;
    font-size:x-large;
    color: blue;
    font-weight: 500;
    width: fit-content;
    background-color: white;

}

.radiology_cards {
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    padding: 10px;
    padding-top: 5px;
    background-color: white;
}

.radiology_card {
    height: 250px;
    margin: 10px;
    max-width: 500px;
    background-color: rgb(195, 242, 227);
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.redtext {
    padding-left: 10px;
    font-size: medium;
    font-weight: 500;
    color: red;
    padding-top: 5px;
}

.heading_and_discount {
    display: flex;
    align-items: baseline;
}

.heading_and_discount > .heading {
    flex: 1;
    font-size:22px;
    font-weight: bolder;
    padding-left: 10px;
    padding-top: 10px;
}

.heading_and_discount > .discount {
    background-color: deepskyblue;
    color: white;
    padding-left: 20px;
    padding-right: 5px;
    font-size: small;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: bold;
}

.content_and_price {
    display: flex;
    align-items: baseline;
    gap: 4rem;
    padding-left: 10px;
    padding-top: 20px;
}

.price {
    padding-right: 20px;
}

.price > button {
    width: 125px;
    height: 40px;
    background-color: rgb(0, 110, 255);
    border: 1px var(--color-light);
    border-radius: 5px;
    color: white;
    font-weight: bolder;
    font-size: 20px;
}

.actual_price {
    display: flex;
    align-items: baseline;
    gap: 0.3rem;
}

.content {
    width: 50%;
    flex: 1;
    font-size: medium;
}

@media (max-width: 800px) {
    .radiology_cards {
        display: flex;
        flex-direction: column;
        grid-template-columns: auto;
        align-items: center;
    }

    .radiology_card {
        height: 250px;
        align-self: center;
    }
}
