.login_main,
.login_form {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login_main_div {
  /* height: 100vh; */
  box-sizing: border-box;
  padding: 1rem 0;
  border-radius: 10px;
}

.login_heading > h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #2645a3;
}

.login_main {
  /* width: 100%; */
  margin: 0 auto;
  padding: 1rem 1.6rem;
  position: relative;
  /* margin-top: 6rem; */
  /* border: 1px solid black; */
  border-radius: 0.4rem;
  background-color: #ffffff;
  /* box-shadow: 0 0.5rem 1rem rgba(69, 69, 69, 0.5); */
}

.login_form_entry {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.num-label {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.login_form_entry > h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #2645a3;
  text-align: center;
}

.helper-text {
  font-size: 0.8rem;
  color: #616161;
  margin-bottom: 0.5rem;
  margin-top: 7px;
}

.otp-label {
  font-size: 0.8rem;
  color: #616161;
  /* font-weight: 400; */
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.login_form_entry > input::placeholder {
  color: #8c8c8c;
}

.loginfooter > img {
  opacity: 0.8;
}

.submitButton {
  width: 30rem;
  padding: 10px;
  font-size: 1.3rem;
  border: 1px solid #8c8c8c;
  border-radius: 5px;
  background-color: #2645a3;
  color: white;
  cursor: pointer;
  margin: 2rem 0;
  margin-bottom: 0.8rem;
}

.submitButton:hover {
  background-color: rgb(36, 29, 134);
}

.signUp_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.signUp_sec > a {
  color: #2645a3;
  font-weight: 500;
  text-decoration: none;
  margin-left: 0.4rem;
}

.wError {
  margin-top: 2rem;
  color: red;
  text-align: center;
}

.btn {
  background-color: #2645a3;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 1rem 0;
  margin-bottom: 0.8rem;
  float: right;
  width: 7rem;
}

@media screen and (max-width: 550px) {
  .login_form_entry > input {
    width: 21rem;
  }
  .submitButton {
    width: 21rem;
  }
}
