.imageCarousel {
  width: 95%;
  margin: 1rem auto;
  height: 310px;
}

.slide img {
  width: 100%;
  height: 300px;
  /* object-fit: cover; */
  margin: auto;
}

.slide {
  width: 100%;
  transform: scale(0.6);
  transition: transform 300ms;
  opacity: 0.8;
  height: 300px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.activeSlide img {
  transform: scale(1);
  opacity: 1;
}

.activeSlide {
  width: 100%;
  transform: scale(0.8);
  opacity: 1;
}

.arrow {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.5rem;
}

.nextArrow {
  right: 6px;
  top: 40%;
}

.prevArrow {
  left: 6px;
  top: 40%;
}

.dots-style {
  bottom: -20px;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 10;
}

.dots-style li button::before {
  display: none;
}

.dots-style li {
  list-style: none;
  margin: 0 0.8rem;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e0e0e0;
  transform: scale(0.7);
  transition: transform 600ms;
}

.dots-style li.slick-active {
  background-color: #9e9e9e;
  transform: scale(1.4);
}

.dots-style li:hover {
  background-color: #9e9e9e;
  transform: scale(1.4);
}

@media screen and (max-width: 900px) {
  .imageCarousel {
    width: 100%;
    height: 220px;
  }
  .slide img {
    height: 100%;
  }
  .slide {
    height: 220px;
  }
}

@media screen and (max-width: 550px) {
  .imageCarousel {
    width: 100%;
    height: 120px;
    margin: 0.5rem auto;
  }
  .slide img {
    height: 100%;
  }
  .slide {
    height: 120px;
  }
  .arrow {
    padding: 3px;
  }
  .nextArrow {
    right: -5px;
    top: 40%;
  }
  .prevArrow {
    left: -5px;
    top: 40%;
    padding-right: 0;
  }
  .dots-style li {
    margin: 0 0.3rem;
  }
}
