.booking_div {
  background-color: #f5f5f5;
}

.booking_div > h2 {
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 1.5rem 2.5rem;
  background-color: white;
  border-radius: 0.4rem;
}

.BookingFor_div {
  font-size: 80%;
  margin: 1rem auto;
  padding: 0.5rem 0;
  background-color: #f5f5f5;
  box-sizing: border-box;
  padding-bottom: 8rem;
}

.bookedFor {
  width: 60%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 1rem;
  background-color: white;
  border-radius: 0.6rem;
}

.add_beforeAvtar {
  font-size: 4rem;
}

.avatars {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 12rem;
}

.add_beforeAvtar > h3,
.addedAvatar .avat_det > h3 {
  font-size: 1.3rem;
  margin: 0;
  font-weight: 600;
}

.addedAvatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.add_beforeAvtar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
  cursor: pointer;
}

.addedAvatar .avat_det {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0.5rem;
  cursor: pointer;
}
.addedAvatar .avat_det_active {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0.5rem;
  border: 0.8px solid gray;
  border-radius: 10px;
  cursor: pointer;
}

.iconLoaction {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-style: italic;
  font-size: 3.5rem;
  color: #000c83;
  width: 170px;
  padding: 10px 8px;
  border: 1px solid gray;
  margin: 2px;
  cursor: pointer;
}

.pickUpLocation {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 1rem;
  margin: 1rem auto;
  width: 60%;
  border-radius: 0.6rem;
  background-color: white;
}

.locs {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: 7px;
  scrollbar-color: "#bdbdbd";

  /* scrollBehavior: 'smooth',
        '&::-webkit-scrollbar': {
          width: 7,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#bdbdbd',
          borderRadius: 2,
        }, */
}

.loc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 8px;
  border: 1px solid gray;
  margin: 2px;
  width: 170px;
  cursor: pointer;
}
.loc_active {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px 8px;
  border: 1px solid black;
  margin: 2px;
  width: 170px;
  cursor: pointer;
}

.selectBookingSlot {
  padding: 1rem;
  margin: 1rem auto;
  width: 60%;
  border-radius: 0.6rem;
  background-color: white;
}

.selectBookingSlot_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconLoaction > p {
  font-size: 1rem;
  margin: 0;
  color: black;
}

.addAddressButton {
  font-size: 1rem;
  font-weight: bold;
  color: #000c83;
  text-align: center;
  margin: 1rem 0;
  border: none;
  background-color: white;
}

.appointmentDate {
  font-size: 1.3rem;
  font-weight: bold;
  border-radius: 0.5rem;
  border: none;
  background-color: #0eb6ff;
  color: white;
  padding: 0.5rem 1rem;
}

.appointmentSelect {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  margin: 1rem 0;
  border-radius: 0.8rem;
  width: 20rem;
}

.appointmentOptions {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.appointmentOption {
  font-size: 0.8rem;
  border: 1px solid black;
  background-color: white;
  border-radius: 0.3rem;
  padding: 0.2rem;
  margin: 0.5rem 1rem;
}

.appointmentSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.appointmentSection > h4 {
  font-size: 1rem;
}

.hideOptions {
  display: none;
}

.activeOption {
  background-color: #0eb6ff;
  border: 1px solid white;
  color: white;
}

.checkoutButton {
  border: none;
  border-radius: 0.2rem;
  padding: 0.4rem 0.6rem;
}

.rotate {
  transform: rotate(180deg);
}

@media (min-width: 1100px) {
  .BookingFor_div {
    display: grid;
    grid-template-columns: auto auto;
    height: 100%;
    width: 80%;
    margin: 0 auto;
  }

  .BookingFor_div > div {
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .bookedFor,
  .pickUpLocation,
  .selectBookingSlot {
    width: 95%;
    margin: 1rem auto;
  }

  .selectBookingSlot {
    height: 100%;
  }
}

@media (max-width: 900px) {
  .BookingFor_div {
    width: 90%;
  }

  .bookedFor,
  .pickUpLocation,
  .selectBookingSlot {
    width: 80%;
  }

  .appointmentSection > h4 {
    font-size: 1.1rem;
  }

  .selectBookingSlot > h3,
  .bookedFor > h3,
  .pickUpLocation > h3 {
    font-size: 1.2rem;
    font-weight: bold;
  }
}

@media (max-width: 500px) {
  .BookingFor_div {
    width: 100%;
    padding: 0rem 0rem;
    padding-bottom: 8rem;
  }

  .avatars {
    justify-content: flex-start;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 10px;
  }

  .bookedFor,
  .pickUpLocation,
  .selectBookingSlot {
    width: 92%;
    padding: 13px;
    padding-bottom: 1rem;
  }

  .selectBookingSlot > h3,
  .bookedFor > h3,
  .pickUpLocation > h3 {
    margin: 0;
    margin-bottom: 0.6rem;
    text-align: left;
    padding: 0.2rem 0.5rem;
    font-weight: 500;
  }

  .add_beforeAvtar > h3,
  .addedAvatar > h3 {
    font-size: 1rem;
    margin: 0;
    font-weight: 600;
  }

  .add_beforeAvtar {
    font-size: 4.5rem;
  }
}
