.dashboard {
    display: grid;
    grid-template-columns: 1fr 4fr;
}

@media only screen and (max-width: 900px) {
    .dashboard {
        display: block;
        margin-bottom: 100px;
    }
}