.close_button {
  font-weight: bolder;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.dialog_body {
  width: 400px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  position: fixed;
}

::placeholder {
  color: black;
}

@media (max-width: 800px) {
  .dialog_body {
    left: 42%;
    width: 350px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 30px;
    align-self: center;
  }
}

.prec {
  display: flex;
}

.prec_img {
  width: 80px;
  height: 80px;
  padding: 5px;
}
