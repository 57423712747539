ul {
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  color: #fff;
}

.container {
  display: inline;
}

.footer-section {
  /* background: #202020; */
  background: #f5f5f5;

  position: relative;

  z-index: -1;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #2196f3;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.row {
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  /* align-items: center; */
}

.cta-text h4 {
  color: #424242;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #424242;
  font-size: 15px;
}
.footer-content1 {
  position: relative;
  z-index: 2;
  padding: 20px 50px;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
/* .footer-logo {
  margin-bottom: 30px;
} */
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 40px;
  margin-right: 8rem;
  font-size: 14px;
  color: #424242;
  line-height: 28px;
}
.footer-social-icon span {
  color: #424242;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #424242;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: #dd4b39;
}
.footer-widget{
  margin-right: 4rem;

}
.footer-widget-heading h3 {
  color: #424242;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #2196f3;
}
.footer-widget ul li {
  display: inline-block;
  width: 80%;
  margin-bottom: 12px;
  margin-right: 10px;
}
.footer-widget ul li a:hover {
  color: #2196f3;
}

.footer-widget ul {
  display: flex;
  flex-direction: column;
  min-width: 13rem;
}

.footer-widget ul li a {
  color: #424242;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 4px 8px;
  height: 40px;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #2196f3;
  /* padding: 13px 20px; */
  padding: auto;
  height: 100%;
  width: 3rem;
  border: 1px solid #2196f3;
  top: 0;
}
.subscribe-form button i {
  color: #424242;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: #f5f5f5;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #424242;
}
.copyright-text p a {
  color: #2196f3;
}
.footer-menu1 li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu1 li:hover a {
  color: #2196f3;
}
.footer-menu1 li a {
  font-size: 14px;
  color: #878787;
}

.google-img {
  width: 10rem;
}

.apple-img {
  width: 9rem;
  margin-left: 9px;
}

.google-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.apple-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 900px) {
  .row {
    width: 100%;
  }
  .footer-content1 {
    padding: 20px 15px;
  }
}

@media screen and (max-width: 550px) {
  .footer-section {
    display: none;
  }
}
