.pakage_box {
  position: relative;
  padding: 0.5rem 0.5rem;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 15rem;
  border-radius: 0.4rem;
}

.pakage_box:nth-child(even) {
  background-color: #deffde;
}

.pakage_box:nth-child(odd) {
  background-color: #f3e4ff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.card_offer {
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 0.4rem;

  --f: 10px; /* control the folded part*/
  --r: 5px; /* control the ribbon shape */
  --t: 2px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  /* background: #BD1550; */
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
  font-weight: 700;
}

.pakage_details {
  padding: 0rem 0.5rem;
}
.pakage_price {
  padding: 0rem 0.5rem;
}
.pakage_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.pakagesName {
  margin-bottom: 0.5rem;
}

.pakage_details {
  margin-bottom: 1rem;
}

.pakage_details > p,
.pakage_about > p {
  font-size: 0.9rem;
}

.pakage_about > p {
  margin: 0;
}

.pakage_details .IPara {
  font-weight: 600;
}
.pack_ol {
  height: 40px;
  padding-left: 18px;
}
.ReactModal__Content {
  inset: 8px !important;
  background-color: #f3e4ff !important;
  padding: 0 !important;
  overflow: hidden !important;
}
.pakage_box_know {
  position: relative;
  padding: 0.5rem 0.5rem;
  margin: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 20rem;
  border-radius: 0.4rem;
}
.pakage_about_know {
  max-height: 100px;
  overflow-y: scroll;
  width: 200px;
}

.pakage_about > button {
  margin: 1rem 0;
  background-color: white;
  border: none;
  cursor: pointer;
  border-radius: 0.2rem;
  padding: 0.4rem 0.8rem;
  font-weight: 600;
}

.pakage_price > span {
  font-size: 1.2rem;
}

.BookNowButton {
  width: 100%;
  padding: 0.6rem;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  margin-top: 0.4rem;
  color: white;
  background-color: #000c83;
  border-radius: 0.4rem;
  max-width: 20rem;
  margin-bottom: 0.5rem;
}

.RemoveNowButton {
  width: 100%;
  padding: 0.6rem;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  margin-top: 0.4rem;
  border-radius: 0.4rem;
  max-width: 20rem;
  margin-bottom: 0.5rem;
  color: rgb(6, 6, 6);
  background-color: rgb(192, 195, 216);
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root.css-1gz1y6s {
  font-size: 1.2rem;
}

.checkoutButton {
  background-color: #000c83;
  color: white;
  font-size: 1.2rem;
}

.requirements-text {
  color: red;
  font-size: 80%;
  margin: 0;
}

@media screen and (max-width: 550px) {
  .pakage_box {
    margin: 1rem 0.5rem;
    width: 15rem;
  }
  .pakagesName {
    font-size: 1rem;
  }
  .requirements-text {
    font-size: 67%;
  }
  .pack_ol {
    margin-bottom: 5px;
  }
  .pack_ol li {
    font-size: 0.8rem;
  }
  .pakage_about > button {
    margin-top: 0;
    margin-bottom: 0.4rem;
    padding: 0.3rem 0.6rem;
    font-weight: 600;
  }
  .test_available {
    font-size: 0.8rem;
  }
  .test_price {
    font-size: 0.8rem;
  }
  .IPara {
    margin-top: 5px;
  }
  .pakage_details > p {
    font-size: 0.75rem;
  }
}
