.sliderImage {
  padding: 2rem 3rem;
}

.slick-slide {
  transform: scale(1);
  transition: 0.3s ease-in-out all;

  opacity: 0.9;
}

.slick-slide.slick-center {
  opacity: 1;
  transform: scale(1.2);
}
.sliderImage > img {
  width: 80%;
  height: 200px;
  object-fit: fill;
  border-radius: 0.4rem;
  box-shadow: rgba(9, 42, 7, 0.64) 0px 3px 8px;
}
/* 
.slick-dots li.slick-active button:before {
  font-size: 50px;
  content: " ";
  line-height: 0px;
} */

/* @media (max-width: 1500px) {
    .sliderImage>img {
        width: 40rem;
    }
}

@media (max-width: 1430px) {
    .sliderImage>img {
        width: 38rem;
    }
}

@media (max-width: 1250px) {
    .sliderImage>img {
        width: 35rem;
    }
} */
