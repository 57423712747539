.search {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.search_opt {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.srch-pack {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.srch-test {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
