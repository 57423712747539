.my_modal {
  position: fixed; /* Stay in place */
  z-index: 1;
  width: 200%; /* Full width */
  height: 500%; /* Full height */
  left: 0;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
}

.thanku {
  width: 357px;
  height: 237px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  position: fixed;
}

.thanku_inner {
  margin: 20px;
}

.close_button {
  font-weight: bolder;
  cursor: pointer;
}

.heading_and_close {
  display: flex;
  border-radius: 5px;
  align-items: center;
}

.dialog_body {
  width: 400px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 43%;
  right: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  position: fixed;
}

::placeholder {
  color: black;
}

@media (max-width: 800px) {
  .dialog_body {
    width: 350px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 30px;
    align-self: center;
  }
}

.prec {
  display: flex;
}

.prec_img {
  width: 80px;
  height: 80px;
  padding: 5px;
}
